<template>
  <div>
    <s-dialog :open="true" to="stds-dialog-create-group" size="md">
      <s-dialog-overlay />
      <s-dialog-panel>
        <s-dialog-content>
          <s-dialog-content-body>
            <div class="py-24">
              <template v-if="!isEditMode">
                <s-text
                  as="h5"
                  role="title5"
                  class="!font-bold text-on-surface-elevation-1 text-center"
                >
                  {{ $t('studio.stu_logged_in.pre_grp_join.create_grp_guide1') }}
                </s-text>
                <safe-html
                  class="mt-8 text-md font-regular leading-lg text-on-surface-elevation-3 text-center"
                  tag="p"
                  :html="$t('studio.stu_logged_in.pre_grp_join.create_grp_guide2')"
                />
              </template>
              <template v-else>
                <s-text
                  as="h5"
                  role="title5"
                  class="!font-bold text-on-surface-elevation-1 text-center"
                >
                  {{ $t('studio.group.home.title_group_name_change_title1') }}
                </s-text>
                <safe-html
                  class="mt-8 text-md font-regular leading-lg text-on-surface-elevation-3 text-center"
                  tag="p"
                  :html="$t('studio.group.home.title_group_name_change_title2')"
                />
              </template>
              <div class="mt-24">
                <input-text
                  name="groupName"
                  :placeholder="
                    !isEditMode
                      ? $t('studio.stu_logged_in.pre_grp_join.create_grp_name_place_holder')
                      : $t('studio.group.home.title_group_name_change_place_holder')
                  "
                  :rules="{
                    required: {
                      value: true,
                      message: $t('studio.stu_logged_in.pre_grp_join.create_grp_val_msg2'),
                      showError: isSubmitted
                    },
                    max_length: 60
                  }"
                  maxLength="60"
                  @update:modelValue="isSubmitted = false"
                  @focus="isSubmitted = false"
                />
              </div>
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button class="w-full" variant="outline" @click="onClose">
            {{ $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button v-if="!isEditMode" class="w-full" @click="onCreate">
            {{ $t('studio.stu_logged_in.pre_grp_join.btn_create_grp') }}
          </s-button>
          <s-button v-else class="w-full" @click="onEdit">
            {{ $t('studio.group.home.title_group_name_change_btn_modify') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target name="stds-dialog-create-group" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { ref, toRefs } from 'vue';

import { createGroupApi, groupHistoryTrackingApi, modifyGroupApi } from '@/apis/group.api';
import safeHtml from '@/components/common/safe-html.vue';
import InputText from '@/components/validation/input-text.vue';
import { STATUS_CODE } from '@/constants/error.const';
import { RuleNames } from '@/enums/validation.enum';
import { useAppStore } from '@/stores/app.store';
import { useBusinessBankStore } from '@/stores/business-bank.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  isEditMode?: string;
}>();

const emit = defineEmits<{
  close: [v: string];
}>();

const userStore = useUserStore();
const businessBankStore = useBusinessBankStore();

const { fetchSimpleMyInfos } = userStore;
const { selectedGroupId, selectedGroupInfo } = storeToRefs(userStore);
const {
  setGroupAddedToHistoryOrNot,
  resetSelectedProductListHistory,
  setAlreadyFetchSelectedProductListHistoryOrNot
} = useAppStore();

const { isEditMode } = toRefs(props);

const { setErrors, setValues, validate, values, meta } = useForm({
  initialValues: {
    groupName: ''
  }
});

const isSubmitted = ref<boolean>(false);

const handleCreateGroup = async (groupName: string) => {
  try {
    const result = await createGroupApi(groupName);
    if (!result) {
      return;
    }
    await fetchSimpleMyInfos(result.groupId);
    setErrors({ groupName: '' });
    businessBankStore.resetData();
    await groupHistoryTrackingApi([result.groupId]);
    setGroupAddedToHistoryOrNot(true);
    resetSelectedProductListHistory();
    setAlreadyFetchSelectedProductListHistoryOrNot(true);
    emit('close', 'createSuccess');
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;
    if (errorCode === STATUS_CODE.GROUP_DUPLICATION) {
      const errorMessage = generateErrorMsg(
        'studio.stu_logged_in.pre_grp_join.create_grp_val_msg3',
        RuleNames.CHECK_DUPLICATE
      );
      setErrors({ groupName: errorMessage });
    }
  }
};

const handleEditGroupName = async (groupId: string, groupName: string) => {
  try {
    await modifyGroupApi(groupId, { groupName });
    setErrors({ groupName: '' });
    emit('close', 'updateSuccess');
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;
    if (errorCode === STATUS_CODE.GROUP_DUPLICATION) {
      const errorMessage = generateErrorMsg(
        'studio.stu_logged_in.pre_grp_join.create_grp_val_msg3',
        RuleNames.CHECK_DUPLICATE
      );
      setErrors({ groupName: errorMessage });
    }
  }
};

const onClose = () => {
  emit('close', 'close');
};

const onCreate = async () => {
  isSubmitted.value = true;
  await validate();
  if (!meta.value.valid) {
    return;
  }
  await handleCreateGroup(values.groupName.trim());
};

const onEdit = async () => {
  isSubmitted.value = true;
  if (values.groupName.trim() === selectedGroupInfo.value?.groupName) {
    emit('close', 'close');
    return;
  }

  await validate();

  if (!meta.value.valid) {
    return;
  }
  await handleEditGroupName(selectedGroupId.value, values.groupName.trim());
  await fetchSimpleMyInfos();
};

const init = () => {
  if (isEditMode.value) {
    setValues({ groupName: selectedGroupInfo.value?.groupName });
  }
};
init();
</script>
